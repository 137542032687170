<template>
  <div class="record-main">
    <div class="Stat">
      <ul>
          <li>
            <div>
              <p class="StatNum">{{StatData.StoryNum}}</p>
              <p class="StatText">故事数</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.ShareNum}}</p>
              <p class="StatText">共享</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.PendingNum}}</p>
              <p class="StatText">待定</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.SelectedNum}}</p>
              <p class="StatText">精选</p>
            </div>
          </li>
          <li>
            <div>
              <p class="StatNum">{{StatData.SpareNum}}</p>
              <p class="StatText">备用</p>
            </div>
          </li>
      </ul>

    </div>
    <div class="header flex">
      <div>

        <a-input
          placeholder="搜索员工姓名"
          style="width: 150px;"
          v-model="searchInput"
        />

        <a-button style="margin-left: 15px;" @click="_reset">重置</a-button>
        <a-button type="primary" style="margin-left: 15px;" @click="_search"
          >查询</a-button
        >
      </div>
    </div>

    <div class="container">
      <a-table
        bordered
        :columns="tableColumns"
        :dataSource="tableData"
        :pagination="false"
        @change="handleTableChange"
      >
        <div slot="StoryNum"  slot-scope="text, record, index"  >
            <a @click="_showUserList(record,index,0,'全部','全部')" style="color: #1890ff;">{{text}}</a>
        </div>
        <div slot="ShareNum"  slot-scope="text, record, index"  >
            <a @click="_showUserList(record,index,1,'是','全部')" style="color: #1890ff;">{{text}}</a>
        </div>
        <div slot="PendingNum"  slot-scope="text, record, index"  >
            <a @click="_showUserList(record,index,2,'全部','待定')" style="color: #1890ff;">{{text}}</a>
        </div>
        <div slot="SelectedNum"  slot-scope="text, record, index"  >
            <a @click="_showUserList(record,index,3,'全部','精选')" style="color: #1890ff;">{{text}}</a>
        </div>
        <div slot="SpareNum"  slot-scope="text, record, index"  >
            <a @click="_showUserList(record,index,4,'全部','备用')" style="color: #1890ff;">{{text}}</a>
        </div>
      </a-table>
      <div style="margin: 20px 0;text-align: right" v-if="count > 10">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="count"
          @change="_PageChange"
        />
      </div>
    </div>

    <a-drawer
      :title="showUserListName+'的故事录音'"
      placement="right"
      width="900"
      :closable="false"
      @close="_onClose"
      :visible="visible"
    >
      
    <recordList :queryData="queryData"></recordList>
    </a-drawer>
  </div>
</template>

<script>
import E from 'wangeditor'
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
import MaterialList from 'components/Common/MaterialList' //选择素材
import recordList from '@/pages/RecordAdmin/list'
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'record-main',
  components: { E, person, MaterialList , recordList},
  data () {
    return {
      ageList: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 70,
          align: 'center'
        },
        {
          title: '员工名称',
          dataIndex: 'UserName',
          align: 'center'
        },
        {
          title: '所属中心',
          dataIndex: 'OrgName',
          align: 'center'
        },
        {
          title: '故事数量',
          dataIndex: 'StoryNum',
          align: 'center',
          sorter:true,
          scopedSlots: { customRender: 'StoryNum' }
        },
        {
          title: '共享',
          dataIndex: 'ShareNum',
          sorter:true,
          align: 'center',
          scopedSlots: { customRender: 'ShareNum' }
        },
        {
          title: '待定',
          dataIndex: 'PendingNum',
          sorter:true,
          align: 'center',
          scopedSlots: { customRender: 'PendingNum' }
        },
        {
          title: '精选',
          dataIndex: 'SelectedNum',
          sorter:true,
          align: 'center',
          scopedSlots: { customRender: 'SelectedNum' }
        },
        {
          title: '备用',
          dataIndex: 'SpareNum',
          sorter:true,
          align: 'center',
          scopedSlots: { customRender: 'SpareNum' }
        }
      ],
      tableData: [],
      visible: false,
      phoneEditor: '',
      personObj: {
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: this.relevanceObject //要传入的数据
      },
      relevanceObject: '',
      RadioDate: null,
      age: [],
      count: 0,
      phoneEditorHtml: '',
      setId: '',
      BgMusicList: [],
      relevanceText: '',

      uploadFile: '', //音频文件
      RadioName: '', //主题名称
      AddRadioDate: null, //播放日期
      Remarks: '', //故事的内容
      addAge: [],
      duration: 0,
      searchInput: '', //创建人搜索

      IssueStatus: '已发布',
      BgMusic: '',
      isShare: '',
      isShareChecked: false,
      materialVisible: false,
      uid: '', //当前人id
      isShareShow: true,
      ShareDisabled: false,
      ageOptionsList: [
        {
          value: '0-2',
          title: '0-2岁'
        },
        {
          value: '2-4',
          title: '2-4岁'
        },
        {
          value: '4-7',
          title: '4-7岁'
        }
      ],
      Label:0,
      queryData:{},
      showUserListName:'',
      StatData:{},
      defaultStatData:{
        StoryNum:0,
        ShareNum:0,
        PendingNum:0,
        SelectedNum:0,
        SpareNum:0
      },
      OrderField:'',
      Order:'',
      currPage:1,
    }
  },
  props: {
    areaid: {
      type: String
    }
  },
  watch: {
    areaid: function (data) {
      if (this.$parent.tabsActive == 3) {
          this.relevanceText = data;
          this.currPage = 1;
          this._getData(1);
      }
    }
  },
  created () {
    let self = this
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    self.uid = user.uid
    for (let i = 0; i < 9; i++) {
      self.ageList.push({ value: i, label: i, children: [] })
      for (let j = i + 1; j < 9; j++) {
        self.ageList[i].children.push({ value: j, label: j })
      }
    }

    this._getData(1);
  },
  methods: {
    _changeShare (value) {
      this.isShare = value
    },
    _changeLabel (value) {
      this.Label = value
    },
    changeTimeToString (time) {
      var res = ''
      var h = Math.floor(time / 3600)
      if (h < 10) {
        h = '0' + h
      }
      var m = time % 3600
      m = Math.floor(m / 60)
      if (m < 10) {
        m = '0' + m
      }
      var s = time % 60
      if (s < 10) {
        s = '0' + s
      }

      res = h + ':' + m + ':' + s
      return res
    },
    _reset () {
      this.RadioDate = null
      this.age = []
      this.relevanceObject = ''
      this.relevanceText = ''
      this.searchInput = ''
      this.isShare = ''
      this.currPage = 1;
    },
    _onClose () {
      this.visible = false
      this.uploadFile = ''
      this.setId = ''
      this.phoneEditorHtml = ''
      this.duration = 0
      this.RadioName = ''
      this.AddRadioDate = null
      this.Remarks = ''
      this.addAge = []
      this.isShareShow = true
      this.isShareChecked = false
      this.ShareDisabled = false
    },
    _search () {
      this._getData(1);
    },
    _getData(page){
      this.currPage = page;
      this.$message.loading('数据加载中...',0);
      this.$axios.get(
        8000078,
        {
          ScreenValue: this.ScreenValue,
          OrgId: this.relevanceText,
          UserName: this.searchInput,
          Page: page,
          Limit: 10,
          OrderField:this.OrderField,
          Order:this.Order
        },
        res => {
          if (res.data.code == 1) {
            this.StatData = res.data.StatData
            this.tableData = res.data.data
            this.count = res.data.count
          } else {
            this.tableData = []
            this.StatData = this.defaultStatData
            this.count = 0
          }
          this.$message.destroy();
        }
      )
    },
    _PageChange (pageNumber) {
      this._getData(pageNumber)
    },
    _showUserList(record,index,type,isShare,Label){
        this.showUserListName = record.UserName;
        this.visible = true;
        this.queryData = {
            UserId:record.UserId,
            isShare:isShare,
            Label:Label
        };
    },
    handleTableChange(pagination, filters, sorter){

      this.OrderField = sorter.field;

      switch (sorter.order) {
        case 'ascend':
          this.Order = 'asc';
          break;
        case 'descend':
          this.Order = 'desc';
          break;
      }

      this._getData(this.currPage);
    }
  }
}
</script>

<style scoped lang="less">
.record-main {
  width: 100%;
  min-height: 100%;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  .header {
    margin-top: 10px;
  }
  .container {
    margin-top: 25px;
    .icon-btn {
      font-size: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
    .state-icon {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
      background: rgb(255, 127, 56);
      margin-right: 4px;
    }
  }
  .person {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1000;
  }
}
.row {
  margin-bottom: 20px;
  .label {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
  }
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.audio {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  .audio-title {
    position: relative;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .audio-upload-time {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 12px;
      color: #aaa;
    }
  }
  .audio-file {
    width: 80px;
    padding: 10px 0;
    background: #fafafc;
    text-align: center;
    margin-left: 10px;
  }
  .audio-length {
    color: #aaa;
    font-size: 14px;
  }
}

.status-list {
    > div {
      margin-top: 2px;
      cursor: pointer;
    }
    span {
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      border: 1px solid #ddd;
      border-radius: 50%;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: #ddd;
    }
  }

  .Label {
    width: 50px;
    margin: 0px auto;
    border-radius: 5px;
  }

  .Label > span {
    font-weight: bold;
  }
  .Stat{
    display:flex;
    margin-bottom: 25px;
  }
  .Stat > ul > li{
    float:left;
    margin-right: 70px;
  }
  .StatNum{
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  .StatText{
    font-size: 10px;
    color: #989595dd;
    text-align: center;
  }

</style>
