<template>
    <div class="audioAdmin">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive==0?'active':''">故事管理</li>
                <li @click="_tabsActive(1)" :class="tabsActive==1?'active':''">问候管理</li>
                <li @click="_tabsActive(2)" :class="tabsActive==2?'active':''">故事素材</li>
                <li @click="_tabsActive(3)" :class="tabsActive==3?'active':''">故事数据</li>
            </ul>
        </div>
        <div class="flex main-container">
            <div class="tree">
                <div class="tree-title">组织架构</div>
                <a-tree :treeData="posts_type_arr" @select="_treeSelect"/>
            </div>


            <div v-show="tabsActive==0" style="flex:1;height: 100%">
                <div class="main">
                    <div class="search-div">
                        <div class="condition">
                            <span>播放时间</span>
                            <a-date-picker style="width: 150px;" placeholder="播放时间" @change="_searchDate"/>
                        </div>
                        <div class="condition">
                            <span>是否发布</span>
                            <a-select style="width: 150px;" v-model="isIssue" placeholder="是否发布">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option value="1">是</a-select-option>
                                <a-select-option value="0">否</a-select-option>
                            </a-select>
                        </div>

                        <div class="condition">
                            <span>班级类型</span>
                            <a-select style="width: 150px;" v-model="classType" placeholder="班级类型">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">课次制</a-select-option>
                                <a-select-option :value="2">课时制</a-select-option>
                            </a-select>
                        </div>
                        <a-button type="primary" @click="_searchData">查询</a-button>
                    </div>

                    <div class="table" style="margin-top: 15px;" v-if="tableData.length!=0">
                        <a-table :columns="tableColumns" :data-source="tableData.list" bordered size="middle" :pagination="false"></a-table>
                        <div style="text-align: right;margin-top: 20px;">
                            <a-pagination :total="count" @change="_pageChange" />
                        </div>
                    </div>
                </div>
            </div>


            <div v-show="tabsActive==1" style="flex:1;height: 100%">
                <div class="main">
                    <div class="search-div">
                        <div class="condition">
                            <span>姓名</span>
                            <a-input placeholder="姓名" v-model="searchInput" style="width: 150px;"/>
                        </div>
                        <!--<div class="condition">-->
                            <!--<span>状态</span>-->
                            <!--<a-select style="width: 150px;" v-model="greetingIsIssue" placeholder="是否发布">-->
                                <!--<a-select-option value="">全部</a-select-option>-->
                                <!--<a-select-option value="1">是</a-select-option>-->
                                <!--<a-select-option value="0">否</a-select-option>-->
                            <!--</a-select>-->
                        <!--</div>-->
                        <a-button type="primary" @click="_greetingInfo(1)">查询</a-button>
                    </div>

                    <div class="table" style="margin-top: 15px;" v-if="tableData.length!=0">
                        <a-table bordered :columns="greetingColumns" :dataSource="greetingData" size="middle" :pagination="false">
                            <div slot="sNumber" slot-scope="text, record, index">
                                {{index+1}}
                            </div>
                            <div slot="greeting" style="color: #2994FF;cursor: pointer" @click="_greetinAlert(record)" slot-scope="text, record, index">
                                {{text}}
                            </div>
                            <div slot="status" slot-scope="text, record, index">
                                <span class="greeting-btn" :style="record.status?'background: #F1FAED;color: #7ACE4C;':'background: #FEEAEE;color: #F33155'">{{record.status?'已完成':'未完成'}}</span>
                            </div>
                        </a-table>
                        <div style="text-align: right;margin-top: 20px;">
                            <a-pagination :total="greetingCount" @change="_greetingPageChange" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="tabsActive==2" style="flex:1;height: 100%">
                <div class="main">
                    <recordAdmin :areaid="recordAdminText"></recordAdmin>
                </div>
            </div>

            <div v-show="tabsActive==3" style="flex:1;height: 100%">
                <div class="main">
                    <recordMain :areaid="recordAdminText"></recordMain>
                </div>
            </div>
        </div>

        <a-drawer title="故事详情" placement="right" width="1000" :closable="false" :visible="visible" @close="onClose">
            <div style="padding-bottom: 55px;display: flex;height: 100%">
                <div class="container" style="margin-top: 0;width: 100%" v-if="detailList==''">
                    <div style="font-size: 20px;color: #ddd;text-align: center">暂无数据</div>
                </div>
                <div style="width: 100%" v-if="detailList">
                    <a-table bordered :columns="alertTableColumns" :dataSource="detailList" :pagination="false">
                        <div slot="snumber" slot-scope="text, record, index">
                            <span>{{index+1}}</span>
                        </div>
                        <div slot="age" slot-scope="text, record, index">
                            <span>{{record.StartAge}}-{{record.EndAge}}岁</span>
                        </div>
                        <div slot="audioFile" slot-scope="text, record, index">
                            <span @click="_skipAudio(record)" style="color: #1890FF;cursor: pointer">打开>></span>
                        </div>
                        <div slot="state" slot-scope="text, record, index">
                            <span class="state-icon" :style="text==1?'background: #75C255':''"></span>
                            <span>{{text==1?'已发布':'未发布'}}</span>
                        </div>
                    </a-table>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="onClose">取消</a-button>
                    <a-button type="primary"  @click="onClose">确定</a-button>
                </div>
            </div>
        </a-drawer>

        <a-drawer title="问候详情" placement="right" width="1000" :closable="false" :visible="greetingVisible" @close="greetingOnClose">
            <div style="padding-bottom: 55px;display: flex;height: 100%">
                <div class="container" style="margin-top: 0;width: 100%" v-if="GreetingdetailList==''">
                    <div style="font-size: 20px;color: #ddd;text-align: center">暂无数据</div>
                </div>
                <div style="width: 100%" v-if="GreetingdetailList">
                    <a-table bordered :columns="GreetingalertTableColumns" :dataSource="GreetingdetailList" :pagination="false">
                        <div slot="sNumber" slot-scope="text, record, index">
                            <span>{{index+1}}</span>
                        </div>

                        <div slot="StudentName" slot-scope="text, record, index">
                            {{record.StudentName}} <span v-if="record.SmallName">({{record.SmallName}})</span>
                        </div>

                        <div slot="classTime" slot-scope="text, record, index">
                            {{record.Week}} {{record.Ctime}} {{record.ClassStage}}
                        </div>

                        <div slot="Age" slot-scope="text, record, index">
                            {{record.Age}}岁{{record.Month}}个月
                        </div>

                        <div slot="audioFile" slot-scope="text, record, index">
                            <img @click="_playAudio(record,index)" v-if="playAudio!=index" style="cursor: pointer" src="https://smart-resource.sikegroup.com/audio-pause.png" alt="">
                            <img @click="_playAudio(record,index)" v-if="playAudio==index" style="cursor: pointer" src="https://smart-resource.sikegroup.com/audio-play.png" alt="">
                        </div>
                        <div slot="IsThisWeek" slot-scope="text, record, index">
                            <span>{{text==1?'本周':'非本周'}}</span>
                        </div>
                    </a-table>
                    <div style="text-align: right;margin-top: 20px;">
                        <a-pagination :total="greetingAlertCount" @change="_GreetingdetailPage" />
                    </div>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="greetingOnClose">取消</a-button>
                    <a-button type="primary"  @click="greetingOnClose">确定</a-button>
                </div>
            </div>
        </a-drawer>
        <audio id="audio" style="position: absolute;top:0;left:0;opacity: 0"></audio>
    </div>
</template>

<script>
    import recordAdmin from '@/pages/RecordAdmin/index'
    import recordMain from '@/pages/RecordAdmin/main'
    export default {
        name: "audioAdmin",
        components: {
            recordAdmin,
            recordMain
        },
        data(){
            return {
                searchDate: '',
                isIssue: '',
                posts_type_arr: [],
                treeData: [
                    {
                        title: '0-0',
                        key: '0-0',
                        children: [
                            {
                                title: '0-0-0',
                                key: '0-0-0',
                                children: [
                                    { title: '0-0-0-0', key: '0-0-0-0' },
                                    { title: '0-0-0-1', key: '0-0-0-1' },
                                    { title: '0-0-0-2', key: '0-0-0-2' },
                                ],
                            },
                            {
                                title: '0-0-1',
                                key: '0-0-1',
                                children: [
                                    { title: '0-0-1-0', key: '0-0-1-0' },
                                    { title: '0-0-1-1', key: '0-0-1-1' },
                                    { title: '0-0-1-2', key: '0-0-1-2' },
                                ],
                            },
                            {
                                title: '0-0-2',
                                key: '0-0-2',
                            },
                        ],
                    },
                ],
                tableColumns: [
                    {
                        title: '姓名',
                        dataIndex: 'TeacherName',
                        width: 80,
                        align: 'center',
                    }
                ],
                tableData: [],
                page: 1,
                count: 0,
                visible: false,
                greetingVisible: false,
                areaid: '',
                detailList: '',
                alertTableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'snumber' },
                    },
                    {
                        title: '故事主题',
                        dataIndex: 'RadioName',
                        align: 'center'
                    },
                    {
                        title: '播放时间',
                        dataIndex: 'RadioDate',
                        align: 'center',
                    },
                    {
                        title: '年龄段',
                        dataIndex: 'Age',
                        align: 'center',
                        scopedSlots: { customRender: 'age' },
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        align: 'center',
                        scopedSlots: { customRender: 'state' },
                    },
                    {
                        title: '音频文件',
                        dataIndex: 'AudioFile',
                        align: 'center',
                        scopedSlots: { customRender: 'audioFile' },
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'CreateTime',
                        align: 'center'
                    },
                    {
                        title: '上传人',
                        dataIndex: 'CreateUser',
                        align: 'center'
                    },
                ],
                tabsActive: 0,
                searchInput: '',
                greetingColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'sNumber' },
                    },
                    {
                        title: '员工姓名',
                        dataIndex: 'TeacherName',
                        align: 'center',
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'SchoolName',
                        align: 'center',
                    },
                    {
                        title: '待录故事',
                        dataIndex: 'UnRadioNum',
                        align: 'center',
                    },
                    // {
                    //     title: '本周客户',
                    //     dataIndex: 'ThisNum',
                    //     align: 'center',
                    // },
                    // {
                    //     title: '非本周客户',
                    //     dataIndex: 'OtherNum',
                    //     align: 'center',
                    // },
                    {
                        title: '已录故事',
                        dataIndex: 'ThisRadioNum',
                        align: 'center',
                        scopedSlots: { customRender: 'greeting' },
                    },
                    {
                        title: '完成状态',
                        dataIndex: 'status',
                        align: 'center',
                        scopedSlots: { customRender: 'status' },
                    },
                ],
                greetingData: [],
                greetingIsIssue: '',
                greetingCount: 1,
                GreetingalertTableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'sNumber' },
                    },
                    {
                        title: '会员姓名',
                        dataIndex: 'StudentName',
                        align: 'center',
                        scopedSlots: { customRender: 'StudentName' },
                    },
                    {
                        title: '课次',
                        dataIndex: 'classTime',
                        align: 'center',
                        scopedSlots: { customRender: 'classTime' },
                    },
                    {
                        title: '状态',
                        dataIndex: 'IsThisWeek',
                        align: 'center',
                        scopedSlots: { customRender: 'IsThisWeek' },
                    },
                    {
                        title: '年龄段',
                        dataIndex: 'Age',
                        align: 'center',
                        scopedSlots: { customRender: 'Age' },
                    },
                    {
                        title: '老师',
                        dataIndex: 'CreateUser',
                        align: 'center',
                    },
                    {
                        title: '音频文件',
                        dataIndex: 'AudioFile',
                        align: 'center',
                        scopedSlots: { customRender: 'audioFile' },
                    },
                ],
                GreetingdetailList: '',
                greetingAlertCount: 1,
                greetingAlertPage: 1,
                greetinTeacherId: '',
                playAudio: -1,
                recordAdminText: '',
                classType: 0,
            }
        },
        created(){
            this.searchDate = this.__moment__(new Date()).format('YYYY/MM/DD');
            if(window.sessionStorage.getItem('slePerson')){
                this.posts_type_arr = JSON.parse(window.sessionStorage.getItem('slePerson'));
            }else{
                this.$message.loading('加载中...',0);
                this.$axios.get(8002,{Pid:0},res=>{
                    this.posts_type_arr = res.data.list;
                    this.$message.destroy();
                    window.sessionStorage.setItem('slePerson',JSON.stringify(res.data.list));
                });
            }
            this._tableColumnsInfo(new Date());
        },
        mounted(){
            this._info();
        },
        methods: {
            _playAudio(data,index){
                let self = this;
                this.playAudio = index;
                let audio = document.getElementById('audio');
                audio.src = data.RadioUrl;
                audio.play();
                audio.addEventListener('ended', function () {
                    self.playAudio += 1;
                    if(self.playAudio < self.GreetingdetailList.length){
                        audio.src = self.GreetingdetailList[self.playAudio].RadioUrl;
                        audio.play();
                    }
                }, false);
            },
            _greetinAlert(data){
                if (!data.StudentIds) {
                    return;
                }
                this.greetingVisible = true;
                this.greetinTeacherId = data.TeacherId;
                this.$axios.get(5836162,{teacherid:data.TeacherId,page:this.greetingAlertPage,limit:10,StudentIds:data.StudentIds},res=>{
                    if(res.data.code==1){
                        this.GreetingdetailList = res.data.data.list;
                        this.greetingAlertCount = res.data.data.count;
                    }else{
                        this.GreetingdetailList = '';
                    }
                });
            },
            greetingOnClose(){
                this.greetingVisible = false;
                this.playAudio = -1;
            },
            _greetingPageChange(i){
                this.greetingCount = i;
                this._greetingInfo();
            },
            _tabsActive(index){
                this.tabsActive = index;
                if(index==1){
                    if(!this.greetingData || this.greetingData.length==0){
                        this._greetingInfo();
                    }
                }
            },
            _skipAudio(res){
                let url = 'https://www.qitianzhen.cn/OnLine/audition?storyId='+res.Id;
                window.open(url);
            },
            _GreetingdetailPage(i){
                this.$axios.get(5836162,{teacherid:this.greetinTeacherId,page:i,limit:10},res=>{
                    if(res.data.code==1){
                        this.GreetingdetailList = res.data.data.list;
                        this.greetingAlertCount = res.data.data.count;
                    }else{
                        this.GreetingdetailList = '';
                    }
                });
            },
            detail(id,type,date){
                this.visible = true;
                this.$axios.get(5836160,{date:date,teacherid:id,age:type},res=>{
                    if(res.data.code==1){
                        this.detailList = res.data.data;
                    }else{
                        this.detailList = '';
                    }
                });
            },
            onClose(){
                this.visible = false;
            },
            _searchDate(e){
                this.searchDate = this.__moment__(e).format('YYYY/MM/DD');
            },
            _pageChange(i){
                this.page = i;
                this._info();
            },
            _greetingInfo(i){
                if(i==1){
                    this.greetingCount = 1;
                }
                this.$message.loading('数据加载中...',0);
                this.$axios.get(5836161,{name:this.searchInput,state:this.greetingIsIssue,areaid: this.areaid,page: this.greetingCount,limit: 10},(res)=>{
                    if(res.data.code==1){
                        this.greetingData = res.data.data.list;
                        this.greetingCount = res.data.data.count;
                    } else {
                        this.greetingCount = 0;
                        this.greetingData = [];
                    }
                    this.$message.destroy();
                })
            },
            _info(i){
                let self = this;
                if(i==1){
                    this.page = 1;
                }
                this.$message.loading('数据加载中...',0);
                this.$axios.get(5836159,{date:this.searchDate,state:this.isIssue,areaid: this.areaid,page: this.page,limit: 10,classtype: this.classType},(res)=>{
                    
                    if(res.data.code==1){
                        this.tableData = res.data.data;
                        this.count = res.data.data.count;
                    } else {
                        this.tableData = [];
                        this.count = 0;
                    }
                    setTimeout(function () {
                        setTimeout(() => {
                            let list = document.getElementsByClassName('tab');
                            for(let i = 0;i < list.length;i++){
                                list[i].addEventListener('click',function (event) {
                                    self.detail(event.target.getAttribute('data-id'),event.target.getAttribute('data-type'),event.target.getAttribute('data-date'))
                                })
                            }
                        }, 1000)

                    })
                    this.$message.destroy();
                })
            },
            _tableColumnsInfo(date){
                let self = this;
                let arr = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六',];
                let day = date.getDay();
                let maxDay = 5 - day;
                let MaxTimeStamp = date.getTime() + maxDay * 86400000;
                let list = [];
                for(let i = 1; i <= 5;i++){
                    let dateTime = MaxTimeStamp - (5 - i) * 86400000;
                    let status1 = 'Status02'+i;
                    let status2 = 'Status24'+i;
                    let status3 = 'Status47'+i;
                    list.push({
                        title: ''+arr[new Date(dateTime).getDay()]+' ('+self._formatDate(new Date(dateTime),'.')+')',
                        children: [
                            {
                                title: '0-2岁',
                                dataIndex: 'Age02'+i,
                                align: 'center',
                                customRender: (text, row, index) => {
                                    // if(text!=0){ 
                                    if(row[status1]!=0||(row[status1]==0 && text!=0)){ 
                                        if(row[status1]==0){
                                            return {
                                                children: <span class="tab status-0" data-id={row.TeacherId} data-type='02' data-date={self._formatDate(new Date(dateTime),'-')}>{text}</span>,
                                        }
                                        }else if(row[status1]==1){
                                            return <span class="tab status-1" data-id={row.TeacherId} data-type='02' data-date={self._formatDate(new Date(dateTime),'-')}>{text}</span>;
                                        }else{
                                            return <span class="tab status-2" data-id={row.TeacherId} data-type='02' data-date={self._formatDate(new Date(dateTime),'-')}>{text}</span>;
                                        }
                                    }

                                },
                            },
                            {
                                title: '2-4岁',
                                dataIndex: 'Age24'+i,
                                align: 'center',
                                customRender: (text, row, index) => {
                                    // if(text!=0){
                                    if(row[status2]!=0||(row[status2]==0 && text!=0)){
                                        if(row[status2]==0){
                                            return <span class="tab status-0" data-id={row.TeacherId} data-type='24' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }else if(row[status2]==1){
                                            return <span class="tab status-1" data-id={row.TeacherId} data-type='24' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }else{
                                            return <span class="tab status-2" data-id={row.TeacherId} data-type='24' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }
                                    }
                                },
                            },
                            {
                                title: '4-7岁',
                                dataIndex: 'Age47'+i,
                                align: 'center',
                                customRender: (text, row, index) => {
                                    // if(text!=0){
                                    if(row[status3]!=0||(row[status3]==0 && text!=0)){
                                        if(row[status3]==0){
                                            return <span class="tab status-0" data-id={row.TeacherId} data-type='47' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }else if(row[status3]==1){
                                            return <span class="tab status-1" data-id={row.TeacherId} data-type='47' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }else{
                                            return <span class="tab status-2" data-id={row.TeacherId} data-type='47' data-date={self._formatDate(new Date(dateTime),'-')}>{{text}}</span>;
                                        }
                                    }
                                },
                            },

                        ]
                    });
                }
                console.log('打印数据')
                console.log(list)
                this.tableColumns = this.tableColumns.concat(list);

            },
            _formatDate(date,separator){
                let y = date.getFullYear();
                let m = date.getMonth() + 1;
                let d = date.getDate();
                m = m > 9 ? m : '0' + m;
                d = d > 9 ? d : '0' + d;
                return y + separator + m + separator + d
            },
            _searchData(){
                this.tableColumns = [
                    {
                        title: '姓名',
                        dataIndex: 'TeacherName',
                        width: 80,
                        align: 'center',
                    }
                ];
                this._tableColumnsInfo(new Date(this.searchDate));
                this._info(1);
            },
            _treeSelect(key,e){
                this.areaid = e.selectedNodes[0].data.props.value;
                if(this.tabsActive==1){
                    this._greetingInfo(1);
                }else if(this.tabsActive==2 || this.tabsActive==3){
                    this.recordAdminText = e.selectedNodes[0].data.props.value;
                }else{
                    this._info(1);
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .audioAdmin{
        height: 100%;
        .main-container{
            height: 100%;
            align-items: end;
            padding-top: 15px;
        }
        .tabs{
            border-bottom: 1px solid #CCD1DC;
            border-left: 1px solid #CCD1DC;
            border-top-left-radius: 5px;
            ul{
                li{
                    display: inline-block;
                    width: 85px;
                    padding: 6px 0;
                    text-align: center;
                    border: 1px solid #CCD1DC;
                    border-bottom: none;
                    cursor: pointer;
                    border-left: 0;
                    &:first-child{
                        border-top-left-radius: 5px;
                    }
                    &:last-child{
                        border-top-left-radius: 0;
                        border-top-right-radius: 5px;
                    }
                }
                .active{
                    background: #1890FF;
                    color: #fff;
                    border: 1px solid #1890FF;
                }
            }
        }
        .tree{
            width: 200px;
            height: 100%;
            overflow-y: scroll;
            padding: 15px;
            border: 1px solid #DFE0E6;
            border-radius: 5px;
            background: #fff;
        }
        .main{
            padding: 20px;
            flex: 1;
            margin-left: 15px;
            height: 100%;
            overflow-y: scroll;
            border-radius: 5px;
            border: 1px solid #DFE0E6;
            background: #fff;
            .tab{
                display: inline-block;
                width: 46px;
                height: 26px;
                line-height: 26px;
                border-radius: 2px;
                background: #F4F8F9;
                vertical-align: middle;
            }
            .condition{
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px;
                span{
                    margin-right: 10px;
                }
            }
            .status-0{
                background: #F4F8F9;
                color: #78909C;
            }
            .status-1{
                background: #F1FAED;
                color: #7ACE4C
            }
            .status-2{
                background: #FEEAEE;
                color: #F33155
            }
        }
    }
    .greeting-btn{
        display: inline-block;
        padding: 4px 8px;
        border-radius: 2px;
        font-size: 14px;
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button{
            width: 120px;
        }
    }
</style>
